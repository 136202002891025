import axios from 'axios'
import { getToken, setToken, removeToken } from '@/utils/auth'

// post 跨域
axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded'

axios.interceptors.request.use(function (config) {
  config.headers['Authorization'] = getToken();
  
  return config;
},function (error) {
  return Promise.reject(error);
});

axios.interceptors.response.use(
  response=>{
    console.log(response.data.code);

    var code = response.data.code;

    if(code==415){
      removeToken();
      window.location.href = "#/";
    }
    else if(code==401){
      console.log(response.data.message);
    }

    return response;
  },
  error => {
    console.log(error);

    return error;
  }
)


export default axios;