import Vue from 'vue'
import Router from 'vue-router'
// import { getToken } from '@/utils/auth' // get token from cookie

Vue.use(Router)

export const constantRoutes = [
    {
      path: '/',
      component: () => import('@/views/index')
    },
    {
      path: '/index',
      name: 'index',
      component: () => import('@/views/index')
    },
    {
      path: '/list',
      component: () => import('@/views/list'),
      children: [{
        path:'search',
        name: 'search',
        meta: {
          title: '搜索'
        },
      },{
        path:'tlgk',
        name: 'tlgk',
        meta: {
          title: '铁路概况',
          typeId: '2044eba8-776d-4195-b54f-b3d00928ed98'
        },
      },
      {
          path:'jgsz',
          name: 'jgsz',
          meta: {
                  title: '机构设置',
                  typeId: '86e29bf7-9e8f-4a28-9361-1001705d3dc6'
          },
      },
      {
          path:'zwdt',
          name: 'zwdt',
          meta: {
                  title: '政务动态',
                  typeId: '939fbcca-8c31-478f-a7dc-6c649648164e'
          },
      },
      {
          path:'djdt',
          name: 'djdt',
          meta: {
                  title: '党建动态',
                  typeId: 'f57b8b67-db9a-4110-bc9b-7ec382a07954'
          },
      },
      {
          path:'fzxc',
          name: 'fzxc',
          meta: {
                  title: '法治宣传',
                  typeId: '39599bb3-78e9-4e84-99b5-2d6a5c1c40e3'
          },
      },
      {
          path:'wnfw',
          name: 'wnfw',
          meta: {
                  title: '为您服务',
                  typeId: '7288376a-b582-4e9a-98fa-c218e35b5c30'
          },
      },
      {
          path:'gzcy',
          name: 'gzcy',
          meta: {
                  title: '公众参与',
                  typeId: '696d571d-54ba-4954-804e-74f4310e4d6a'
          },
      },
      {
          path:'tpxw',
          name: 'tpxw',
          meta: {
                  title: '图片新闻',
                  typeId: 'ee0ea72a-75bf-4970-ad34-11e3132bc6a3'
          },
      },
      {
          path:'jjjc',
          name: 'jjjc',
          meta: {
                  title: '纪检监察',
                  typeId: '8e7f6d22-2b5b-4efc-b354-dbcddb46a8b3'
          },
      },
      {
          path:'wmcj',
          name: 'wmcj',
          meta: {
                  title: '文明创建',
                  typeId: 'a4870553-44e0-4172-91db-263cedf5938f'
          },
      },
      {
          path:'tzgg',
          name: 'tzgg',
          meta: {
                  title: '通知公告',
                  typeId: 'de3958c2-0010-4009-b7e1-15bfdcc60cef'
          },
      }]
    },
    {
      path: '/listTab',
      name: 'listTab',
      component: () => import('@/views/listTab')
  },
    {
      path: '/detail',
      name: 'detail',
      component: () => import('@/views/detail')
    },
    {
      path: '/searchResult',
      name: 'searchResult',
      component: () => import('@/views/searchResult')
    },
   
  ];
  
  var router = new Router({
    routes: constantRoutes
  })
  
  router.beforeEach((to, from, next) => {
    next();
    // const hasToken = getToken()
  
    // if (hasToken != null) {
    //   if (to.path === '/login') {
    //     // if is logged in, redirect to the home page
    //     next({ path: '/list' })
    //   }
    //   else {
    //     next();
    //   }
    // }
    // else {
    //   if (to.path === '/login') {
    //     next();
    //   }
    //   else {
    //     next(`/login?redirect=${to.path}`);
    //   }
    // }
  });

export default router;