<template>
  <div id="app">
    <!-- <app-header/> -->
    <router-view/>
    <!-- <app-footer/> -->
  </div>
</template>



<script>
// import AppHeader from '@/components/header.vue'
// import bootstrap from '@/assets/js/bootstrap.js'

export default {
  name: 'App',
  // components: {
  //   AppHeader,AppFooter
  // }
  methods:{

  }
}
</script>
<style>
@import 'assets/css/bootstrap.css';
@import 'assets/font/iconfont.css';
*{
  margin:0;
  padding:0;
  
  }
  ul{
    list-style: none;
  }
</style>